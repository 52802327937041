import React, { useContext, useEffect } from 'react';
//context
import { GlobalContext } from 'context/';
//gatsby
import { Link } from 'gatsby';
//styles
import styled from 'styled-components';
import { rem } from 'polished';
import vars from 'components/styles/varss';
//components
import Layout from 'components/ui/layout';
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';

//styled
const Error404Wrap = styled.section`
  position: relative;
  padding-top: ${rem('100px')};
  padding-bottom: ${rem('100px')};
  text-align: center;
  h1 {
    font-size: ${rem('64px')}!important;
    line-height: 1.19!important;
    letter-spacing: -0.02em!important;
    margin-bottom: ${rem('20px')}!important;
  }
  svg {
    margin-bottom: ${rem('40px')};
    max-width: ${rem('392px')};
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  a {
    color: ${vars.colors.darkBlue}!important;
    &:hover,
    &:active,
    &:focus {
     text-decoration: underline;
    }
  }
`;

const Error404 = () => {

  //use Context
  const [context, setContext] = useContext(GlobalContext);

  //set header to white
  useEffect(() => {
    setContext({
      ...context,
      headerWhite: true
    });
    return () => {
      setContext({
        ...context,
        headerWhite: false
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <Error404Wrap>
        <Container>
          <svg viewBox="0 0 392 180" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width={392} height={180} rx={12} fill="#E9D7FA" /><path d="M24 36c0-6.627 5.373-12 12-12h320c6.627 0 12 5.373 12 12v144H24V36Z" fill="#fff" /><path d="M196 83.25a18.75 18.75 0 1 0 0 37.501 18.75 18.75 0 0 0 0-37.501Zm-9.425 15.588a3.126 3.126 0 1 1 6.252.075 3.126 3.126 0 0 1-6.252-.075ZM196 114.387c-3.45 0-6.25-1.962-6.25-4.375 0-2.412 2.8-4.375 6.25-4.375s6.25 1.963 6.25 4.375c0 2.413-2.8 4.375-6.25 4.375ZM202.25 102a3.12 3.12 0 0 1-2.887-1.929 3.118 3.118 0 0 1 .677-3.406 3.126 3.126 0 1 1 2.21 5.335Z" fill="#907AFF" /></svg>
          <Heading heading1>That page doesn’t exist.</Heading>
          <p>Head back to our <Link to="/">homepage</Link>, or contact <a href="https://support.refersion.com/en/" target="_blank" rel="noreferrer">support</a></p>
        </Container>
      </Error404Wrap>
    </Layout>
  );
};

export default Error404;